import React, {Component} from "react";
import ReactTable from "react-table-v6";
import selectTableHOC from "react-table-v6/lib/hoc/selectTable";
import {Container, Button, Grid, Header, Icon, Modal} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import Provider from "../../../Services/AdConfigurationDataProvider";
import AdProfileForm from "./AdProfileForm";
import FileSaver from "file-saver";
import ProfileExportModal from "./ProfileExportModal";
import ProfileImportModal from "./ProfileImportModal";
import PropTypes from "prop-types";
import ContingentButton from "../../ContingentButton";

const SelectTable = selectTableHOC(ReactTable);

Provider.init({baseURL: process.env.AD_CONFIG_URL});

class AdConfigurationMasterDetailView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            selectedKey: "",
            newClicked: false,
            authorized: false,
            triggerRefresh: false,
            selectedRows: {},
            allBrands: [],
            allPlatforms: [],
            allContentTypes: [],
            dataToDownload: [],
            allProfileFieldKeys: [],
            filename: "export.json",
            showDeactivationModal: false,
            showExportModal: false,
            showImportModal: false,
            userIsEditor: false
        };

        this.loadData = this.loadData.bind(this);
        this.onClickNew = this.onClickNew.bind(this);
        this.onClickDuplicate = this.onClickDuplicate.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.toggleShowDeactivationModal = this.toggleShowDeactivationModal.bind(this);
        this.confirmDeactivation = this.confirmDeactivation.bind(this);

        this.duplicateWithSuffix = this.duplicateWithSuffix.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
        this.exportJSON = this.exportJSON.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.permissions) !== JSON.stringify(this.props.permissions)) {
            console.log("AdConfigurationMasterDetailView.componentDidUpdate permissions:", this.props.permissions);
            this.updatePermissions();
        }
    }

    confirmDeactivation() {
        if (this.state.userIsEditor) {
            Provider.deactivate(this.state.selectedKey, this.props.user).then(response => {
                if (response.hasOwnProperty("error")) throw new Error(response.error);
                this.toggleShowDeactivationModal();
                this.loadData();
                this.setState({triggerRefresh: !this.state.triggerRefresh});
            }).catch(error => {
                this.toggleShowDeactivationModal();
                this.props.toast("Error Deactivating Profile", error.message);
            });
        }
    }

    exportJSON(filename) {
        if (!filename) {
            filename = "export.json";
        }
        const records = [];
        for (const record of Object.values(this.state.selectedRows)) {
            records.push(record);
        }

        let file = new File([JSON.stringify(records)], filename, {type: "application/json;charset=utf-8"});
        FileSaver.saveAs(file);
        this.setState({showExportModal: false});
    }

    componentDidMount() {
        this.updatePermissions();
        this.loadData();
    }

    updatePermissions = () => {
        let updatedUserIsEditor = false;
        for (const permission of this.props.permissions) {
            updatedUserIsEditor ||= (permission.service === "all" && permission.module === "all" && permission.scope === "all" && permission.role === "aspen-power") || (permission.module === this.props.module && permission.service === this.props.service && permission.role === "editor");
            if (updatedUserIsEditor) {
                console.log("AdConfigurationMasterDetailView.updatePermissions updatedUserIsEditor:", updatedUserIsEditor);
                break;
            }
        }
        this.setState({userIsEditor: updatedUserIsEditor});
    }

    loadData() {
        this.setState({loading: true}, () => {
            Provider.getAll(this.props.user).then(data => {
                if (data.hasOwnProperty("error")) {
                    this.setState({
                        data: [],
                        allBrands: [],
                        allPlatforms: [],
                        allContentTypes: [],
                        allProfileFieldKeys: []
                    });
                    this.props.toast("Error Retrieving Data", data.error.message);
                } else {
                    this.setState({
                        data,
                        allBrands: this.props.getAllPotentialProperties("brand", data),
                        allPlatforms: this.props.getAllPotentialProperties("platform", data),
                        allContentTypes: this.props.getAllPotentialProperties("contentType", data),
                        allProfileFieldKeys: Array.from(new Set(data.map(adProfile => Object.keys(adProfile.profile)).flat()))
                    });
                }
            }).catch(error => {
                this.props.toast("Error Retrieving Data", error.message);
            }).finally(() => this.setState({loading: false}));
        });
    }

    onClickNew() {
        if (this.state.userIsEditor) {
            Provider.getNewProfileId(this.props.user).then(response => {
                if (response.hasOwnProperty("error")) throw new Error(response.error);
                this.setState({selectedKey: response.id, newClicked: true});
            }).catch(error => this.props.toast(`Error Getting New Profile ID`, error.message));
        }
    }

    onClickDuplicate() {
        if (this.state.userIsEditor) {
            Provider.clone(this.state.selectedKey, this.props.user).then(data => {
                if (data.hasOwnProperty("error")) throw new Error(data.error);
                this.setState({selectedKey: data._id, newClicked: false});
            }).then(() => {
                this.loadData();
            }).catch(error => {
                console.error(error);
                this.props.toast(`Error Duplicating ${this.state.selectedKey}`, error.message);
            });
        }
    }

    duplicateWithSuffix = async () => {
        if (this.state.userIsEditor) {
            Provider.get(this.state.selectedKey).then(response => {
                this.setState({newClicked: true}, async () => {
                    const duplicatedKey = `${this.state.selectedKey}-ab`;
                    await this.saveProfile(duplicatedKey, response.brand, response.platform, response.contentType, response.profile);
                    this.setState({newClicked: false, selectedKey: duplicatedKey});
                });
            });
        }
        this.setState({selectedKey: this.state.selectedKey});
    };

    onClickSave = async (brand, platform, contentType, profile) => {
        if (this.state.userIsEditor) {
            console.log("AdConfigurationMasterDetailView.onClickSave", brand, platform, contentType, profile);
            await this.saveProfile(this.state.selectedKey, brand, platform, contentType, profile);
        }
    }

    saveProfile = async (key, brand, platform, contentType, profile) => {
        console.log("(AdConfigurationMasterDetailView.saveProfile)", brand, platform, contentType, profile);
        try {
            let updatedProfile = {
                _id: key,
                brand,
                platform,
                contentType,
                profile
            };

            let missingFields = checkForMissingFields(updatedProfile);
            console.log("(AdConfigurationMasterDetailView.saveProfile) missing fields: ", missingFields);

            if (missingFields.length <= 0) {
                if (this.state.newClicked) {
                    await Provider.newProfile(updatedProfile, this.props.user).then(response => {
                        if (response.hasOwnProperty("error")) throw new Error(response.error);
                        this.setState({newClicked: false}, () => {
                            this.loadData();
                        });
                    }).catch(error => this.props.toast("Error Creating Profile", error.message));
                } else {
                    await Provider.update(updatedProfile, this.props.user).then(response => {
                        if (response.hasOwnProperty("error")) throw new Error(response.error);
                        this.loadData();
                    }).catch(error => this.props.toast("Error Updating Profile", error.message));
                }
            } else {
                let missingFieldsString = missingFields.reduce((accumulator, missingField) => {
                    accumulator += accumulator ? `, ${missingField}` : missingField;
                    return accumulator;
                }, "");
                throw new Error(`Please enter the following fields: ${missingFieldsString}`);
            }
        } catch (error) {
            this.props.toast("Error Saving", error.message);
            console.error(error);
        }
    }

    toggleShowDeactivationModal() {
        this.setState({showDeactivationModal: !this.state.showDeactivationModal});
    }

    render() {
        let that = this;
        return (
            <Grid>
                <ProfileExportModal
                    selectedProfiles={this.state.selectedRows}
                    open={this.state.showExportModal}
                    setOpen={showExportModal => this.setState({showExportModal})}
                    exportJSON={this.exportJSON}
                />
                <ProfileImportModal
                    open={this.state.showImportModal}
                    setOpen={showImportModal => this.setState({showImportModal})}
                    saveProfile={this.onClickSave}
                    updateProfiles={this.loadData}
                    profiles={this.state.data}
                    AdConfigurationDataProvider={Provider}
                />
                <Grid.Row>
                    <Grid.Column width={10} className="masterContainer">
                        <Grid>
                            <Grid.Column floated="right" width={16}>
                                <Container fluid textAlign="right" className="actionBarContainer">
                                    <Button.Group>
                                        <Button icon onClick={() => this.setState({showExportModal: true})} disabled={Object.keys(this.state.selectedRows).length < 1}><Icon name="download"/>Export&nbsp;&nbsp;&nbsp;</Button>
                                        <Button.Or text=""/>
                                        <Button icon onClick={() => this.setState({showImportModal: true})} disabled={!this.state.userIsEditor}>&nbsp;&nbsp;&nbsp;<Icon name="upload"/>Import</Button>
                                    </Button.Group>
                                    <Button.Group>
                                        <Button onClick={this.loadData} attached="left" secondary><Icon name="refresh" />Refresh&nbsp;&nbsp;&nbsp;</Button>
                                        <Button.Or text=""/>
                                        <Button onClick={this.onClickNew} attached="right" primary disabled={!this.state.userIsEditor}>&nbsp;&nbsp;&nbsp;<Icon name="plus" />Create New</Button>
                                    </Button.Group>
                                </Container>
                            </Grid.Column>
                        </Grid>

                        <Modal
                            size="tiny"
                            open={this.state.showDeactivationModal}
                            onClose={this.toggleShowDeactivationModal}
                            closeOnDimmerClick={false}
                        >
                            <Modal.Header>Confirm Deactivation</Modal.Header>
                            <Modal.Content><p>Are you sure you want to deactivate {this.state.selectedKey}?</p></Modal.Content>
                            <Modal.Actions>
                                <Button negative content="No" onClick={this.toggleShowDeactivationModal}/>
                                <Button positive content="Yes" onClick={this.confirmDeactivation}/>
                            </Modal.Actions>
                        </Modal>
                        {!this.state.data.error ?
                            <SelectTable
                                selectAll={false}
                                toggleAll={false}
                                selectType="checkbox"
                                toggleSelection={
                                    (_id, hmm, row) => {
                                        if (this.state.selectedRows.hasOwnProperty(_id)) {
                                            const {[_id]: undefined, ...updatedSelectedRows} = this.state.selectedRows;
                                            this.setState({selectedRows: updatedSelectedRows});
                                        } else {
                                            this.setState({selectedRows: Object.assign({}, this.state.selectedRows, {[_id]: row})});
                                        }
                                        console.log("AdConfigurationMasterDetailView.toggleSelection: selected rows: ", this.state.selectedRows);
                                    }
                                }
                                isSelected={_id => this.state.selectedRows.hasOwnProperty(_id)}
                                keyField="_id"
                                SelectAllInputComponent={() => <div />}
                                columns={[
                                    { Header: "Profile ID", accessor: "_id" },
                                    { Header: "Brand", accessor: "brand" },
                                    { Header: "Platform", accessor: "platform" },
                                    { Header: "Content Type", accessor: "contentType" },
                                    { Header: "Deactivated", accessor: "deactivated",
                                        Cell: row => <span>{row.value.toString()}</span>,
                                        filterMethod: (filter, rows) => rows[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase())},
                                    { Header: "Created", accessor: "created",
                                        Cell: row => (<span>{new Date(row.value).toLocaleString("EN-US")}</span>),
                                        filterMethod: (filter, rows) => new Date(rows[filter.id]).toLocaleString().includes(filter.value.toLowerCase())},
                                    { Header: "Last Updated", accessor: "lastUpdated",
                                        Cell: row => (<span>{new Date(row.value).toLocaleString("EN-US")}</span>),
                                        filterMethod: (filter, rows) => new Date(rows[filter.id]).toLocaleString().includes(filter.value.toLowerCase())},
                                ]}
                                defaultSorted={[
                                    {
                                        id: "brand",
                                        desc: false
                                    }, {
                                        id: "_id",
                                        desc: false
                                    }, {
                                        id: "contentType",
                                        desc: false
                                    }, {
                                        id: "platform",
                                        desc: false
                                    }
                                ]}
                                data={this.state.data}
                                loading={this.state.loading}
                                filterable
                                defaultFilterMethod={(filter, rows) => rows[filter.id].toLowerCase().includes(filter.value.toLowerCase())}
                                className="-striped -highlight"
                                getTrProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onClick(event, handleOriginal) {
                                            that.setState({selectedKey: rowInfo.row._id, newClicked: false});
                                        },
                                        style: {
                                            background: rowInfo && rowInfo.row && that.state.selectedKey === rowInfo.row._id ? "rgba(65, 83, 175, .5)" : "",
                                        }
                                    };
                                }}
                                style={{height: "75vh"}}
                            /> : ""
                        }
                    </Grid.Column>
                    <Grid.Column width={6} className="detailsContainer">
                        <AdProfileForm
                            selectedKey={this.state.selectedKey}
                            newClicked={this.state.newClicked}
                            onClickSave={this.onClickSave}
                            onClickDuplicate={this.onClickDuplicate}
                            duplicateWithSuffix={this.duplicateWithSuffix}
                            triggerRefresh={this.state.triggerRefresh}
                            reloadData={this.loadData}
                            showDeactivationModal={this.toggleShowDeactivationModal}
                            allBrands={this.state.allBrands}
                            allPlatforms={this.state.allPlatforms}
                            allContentTypes={this.state.allContentTypes}
                            allProfileFieldKeys={this.state.allProfileFieldKeys}
                            listToDatalist={this.props.listToDatalist}
                            toast={this.props.toast}
                            user={this.props.user}
                            userIsEditor={this.state.userIsEditor}
                            service={this.props.service}
                            module={this.props.module}
                            permissions={this.props.permissions}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

const  checkForMissingFields = profile => {
    console.log("checking for missing fields: ", profile);
    let missingFields = [];
    if (!profile._id) {
        missingFields.push("_id");
    }
    if (!profile.brand) {
        missingFields.push("brand");
    }
    if (!profile.platform) {
        missingFields.push("platform");
    }
    if (!profile.profile) {
        missingFields.push("profile");
    }
    if (!profile.contentType) {
        missingFields.push("content type");
    }
    return missingFields;
};

AdConfigurationMasterDetailView.propTypes = {
    service: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    permissions: PropTypes.string.isRequired,
    userPermissions: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    toast: PropTypes.func.isRequired,
    getAllPotentialProperties: PropTypes.func.isRequired,
    listToDatalist: PropTypes.func.isRequired
};

export default AdConfigurationMasterDetailView;
