import React, {Component} from "react";
import {Button, Container, Divider, Form, Grid, Header, Icon, Input, Message, Select} from "semantic-ui-react";
import Provider from "../../../Services/AdConfigurationDataProvider";
import JSONEditor from "jsoneditor";
import ContingentButton from "../../ContingentButton";

export default class AdProfileForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingProfile: false,
            brand: "",
            contentType: "",
            platform: "",
            deactivated: false,
            selectedProfileFieldKey: "",
            selectedProfileFieldValue: "",
            allProfileFieldKeys: [],
            allBrands: [],
            allPlatforms: [],
            allContentTypes: [],
            currentEditorText: {},
            initialEditorText: {}
        };

        this.onClickSave = this.onClickSave.bind(this);
        this.onClickDeactivate = this.onClickDeactivate.bind(this);
        this.onClickDuplicate = this.onClickDuplicate.bind(this);
        this.onClickDuplicateWithSuffix = this.onClickDuplicateWithSuffix.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.addFieldToProfile = this.addFieldToProfile.bind(this);
    }

    componentDidMount() {
        const options = {
            mode: "code"
        };
        this.jsoneditor = new JSONEditor(this.container, options);
        this.jsoneditor.set(this.state.currentEditorText);
    }

    onClickDuplicateWithSuffix = () => {
        if (this.props.selectedKey) {
            this.props.duplicateWithSuffix().then(() => {
                this.getProfile(this.props.selectedKey);
            });
        }
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.selectedKey !== nextProps.selectedKey ||
            this.props.newClicked !== nextProps.newClicked ||
            this.state.brand !== nextState.brand ||
            this.state.platform !== nextState.platform ||
            this.state.contentType !== nextState.contentType ||
            this.props.allBrands !== nextProps.allBrands ||
            this.props.allPlatforms !== nextProps.allPlatforms ||
            this.props.allContentTypes !== nextProps.allContentTypes ||
            this.state.loadingProfile !== nextState.loadingProfile ||
            this.state.currentEditorText !== nextState.currentEditorText ||
            this.state.initialEditorText !== nextState.initialEditorText ||
            this.state.deactivated !== nextState.deactivated ||
            this.props.triggerRefresh !== nextProps.triggerRefresh ||
            this.props.allProfileFieldKeys !== nextProps.allProfileFieldKeys ||
            this.state.allProfileFieldKeys !== nextState.allProfileFieldKeys ||
            this.state.allBrands !== nextState.allBrands ||
            this.state.allPlatforms !== nextState.allPlatforms ||
            this.state.allContentTypes !== nextState.allContentTypes ||
            this.state.selectedProfileFieldKey !== nextState.selectedProfileFieldKey ||
            this.state.selectedProfileFieldValue !== nextState.selectedProfileFieldValue;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.newClicked !== this.props.newClicked) {
            this.setState({
                brand: "",
                platform: "",
                contentType: "",
                deactivated: false,
                initialEditorText: {},
                currentEditorText: {},
                selectedProfileFieldKey: "",
                selectedProfileFieldValue: ""
            }, () => {
                this.jsoneditor.set(this.state.currentEditorText);
            });
        } else if (!this.props.newClicked && prevProps.selectedKey !== this.props.selectedKey) {
            this.getProfile(this.props.selectedKey);
        } else if (this.state.currentEditorText !== prevState.currentEditorText) {
            this.jsoneditor.set(this.state.currentEditorText);
        } else if (this.props.triggerRefresh !== prevProps.triggerRefresh) {
            this.getProfile(this.props.selectedKey);
        }

        if (this.props.allProfileFieldKeys !== prevProps.allProfileFieldKeys) {
            const allProfileFieldKeys = new Set(this.props.allProfileFieldKeys);
            for (const key of this.state.allProfileFieldKeys) {
                allProfileFieldKeys.add(key);
            }
            this.setState({allProfileFieldKeys: Array.from(allProfileFieldKeys).sort()});
        }

        if (this.props.allBrands !== prevProps.allBrands) {
            const allBrands = new Set(this.props.allBrands);
            for (const brand of this.state.allBrands) {
                allBrands.add(brand);
            }
            this.setState({allBrands: Array.from(allBrands).sort()});
        }

        if (this.props.allPlatforms !== prevProps.allPlatforms) {
            const allPlatforms = new Set(this.props.allPlatforms);
            for (const platform of this.state.allPlatforms) {
                allPlatforms.add(platform);
            }
            this.setState({allPlatforms: Array.from(allPlatforms)});
        }

        if (this.props.allContentTypes !== prevProps.allContentTypes) {
            const allContentTypes = new Set(this.props.allContentTypes);
            for (const contentType of this.state.allContentTypes) {
                allContentTypes.add(contentType);
            }
            this.setState({allContentTypes: Array.from(allContentTypes)});
        }
    }

    getProfile = key => {
        this.setState({loadingProfile: true}, () => {
            Provider.get(key).then(data => {
                this.setState({
                    brand: data.brand,
                    platform: data.platform,
                    contentType: data.contentType,
                    deactivated: data.deactivated,
                    currentEditorText: data.profile,
                    initialEditorText: data.profile
                }, () => {
                    this.updateCurrentEditorText();
                });
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                this.setState({loadingProfile: false, selectedProfileFieldKey: "", selectedProfileFieldValue: ""});
            });
        });
    };

    onClickDeactivate = () => {
        if (this.state.deactivated === false) {
            if (this.props.selectedKey) {
                this.props.showDeactivationModal();
            } else {
                this.props.toast("", "Please select a profile to deactivate.");
            }
        } else {
            Provider.reactivate(this.props.selectedKey).then(response => {
                console.log("(AdProfileForm.onClickDeactivate) reactivation response:", response);
                this.setState({deactivated: response.deactivated});
                this.props.reloadData();
            }).catch(error => {
                console.error("(AdProfileForm.onClickDeactivate)", error);
            });
        }
    };

    onClickSave = async () => {
        await new Promise(resolve => {
            resolve(this.jsoneditor.get());
        }).then(profile => {
            this.setState({currentEditorText: profile, initialEditorText: profile});
        }).then(async () => {
            console.log("(AdProfileForm.onClickSave)", this.state.brand, this.state.platform, this.state.contentType, this.state.currentEditorText);
            await this.props.onClickSave(this.state.brand, this.state.platform, this.state.contentType, this.state.currentEditorText);
        }).then(() => {
            this.jsoneditor.set(this.state.initialEditorText);
        }).catch(error => {
            this.props.toast("Error", "There was an error saving your profile. Check that your JSON is valid.");
            console.error("(AdProfileForm.onClickSave) error: ", error);
        });
    }

    onClickDuplicate = () => this.props.onClickDuplicate();

    updateCurrentEditorText = () => {
        if(this.jsoneditor) {
            this.jsoneditor.set(this.state.currentEditorText);
        }
    }

    addFieldToProfile = () => {
        new Promise(resolve => {
            console.log("adding field to profile")
            const updatedEditorText = this.jsoneditor.get();
            updatedEditorText[this.state.selectedProfileFieldKey] = this.state.selectedProfileFieldValue;
            resolve(updatedEditorText);
        }).then(updatedEditorText => {
            this.setState({currentEditorText: updatedEditorText});
        }).then(() => {
            this.setState({
                selectedProfileFieldKey: "",
                selectedProfileFieldValue: ""
            });
            this.jsoneditor.set(this.state.currentEditorText);
        });
    }

    render() {
        return (
            <Container>
                <Message icon color="yellow" hidden={!this.state.loadingProfile}>
                    <Icon loading name="spinner" size="huge" />
                    <Message.Content>
                        The profile with the id {this.props.selectedKey} is currently being loaded.
                    </Message.Content>
                </Message>
                <Message icon color="yellow" hidden={this.props.selectedKey.length > 0}>
                    <Icon name="warning" size="huge" />
                    <Message.Content>
                        Please select a profile or click "Create New" to continue.
                    </Message.Content>
                </Message>
                <Form hidden={this.state.loadingProfile || this.props.selectedKey.length === 0}>
                    <Form.Group widths="equal">
                        <ContingentButton
                            onClick={this.onClickDeactivate}
                            positive={this.state.deactivated}
                            negative={!this.state.deactivated}
                            fluid
                            service={this.props.service}
                            module={this.props.module}
                            scope="all"
                            allPermissions={this.props.permissions}
                            user={this.props.user}
                        >{this.state.deactivated ? "Reactivate" : "Deactivate"}
                        </ContingentButton>
                        <ContingentButton
                            onClick={this.onClickSave}
                            primary
                            fluid
                            service={this.props.service}
                            module={this.props.module}
                            scope="all"
                            allPermissions={this.props.permissions}
                            user={this.props.user}
                        >Save</ContingentButton>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <ContingentButton
                            onClick={this.onClickDuplicate}
                            fluid
                            service={this.props.service}
                            module={this.props.module}
                            scope="all"
                            allPermissions={this.props.permissions}
                            user={this.props.user}
                        >Duplicate</ContingentButton>
                        <ContingentButton
                            onClick={this.onClickDuplicateWithSuffix}
                            disabled={this.props.selectedKey.length > 3 && this.props.selectedKey.slice(-3) === "-ab"}
                            fluid
                            service={this.props.service}
                            module={this.props.module}
                            scope="all"
                            allPermissions={this.props.permissions}
                            user={this.props.user}
                        >Create Ad Blocker Clone</ContingentButton>
                    </Form.Group>
                    <Divider section />
                    <Form.Field>
                        <Header htmlFor="selectedKey">Profile ID: {this.props.selectedKey} {this.state.deactivated ? "(Deactivated)" : ""}</Header>
                    </Form.Field>
                    <Form.Group widths="equal">
                        <Form.Field
                            label="Brand"
                            control={Select}
                            allowAdditions
                            search
                            options={
                                this.state.allBrands.map(brand => {
                                    return {key: brand, text: brand, value: brand};
                                })
                            }
                            value={this.state.brand}
                            onChange={(event, {value}) => this.setState({brand: value})}
                            onAddItem={(event, {value}) => this.setState({brand: value, allBrands: this.state.allBrands.concat(value).sort()})}
                            fluid
                        />
                        <Form.Field
                            label="Platform"
                            control={Select}
                            allowAdditions
                            search
                            options={
                                this.state.allPlatforms.map(platform => {
                                    return {key: platform, text: platform, value: platform};
                                })
                            }
                            value={this.state.platform}
                            onChange={(event, {value}) => this.setState({platform: value})}
                            onAddItem={(event, {value}) => this.setState({platform: value, allPlatforms: this.state.allPlatforms.concat(value).sort()})}
                            fluid
                        />
                        <Form.Field
                            label="Content Type"
                            control={Select}
                            allowAdditions
                            search
                            options={
                                this.state.allContentTypes.map(contentType => {
                                    return {key: contentType, text: contentType, value: contentType};
                                })
                            }
                            value={this.state.contentType}
                            onChange={(event, {value}) => this.setState({contentType: value})}
                            onAddItem={(event, {value}) => this.setState({contentType: value, allContentTypes: this.state.allContentTypes.concat(value).sort()})}
                            fluid
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            label="Profile Property Fields"
                            control={Select}
                            allowAdditions
                            search
                            options={this.state.allProfileFieldKeys.map(field => {
                                return {key: field, text: field, value: field}
                            })}
                            onChange={(event, {value}) => this.setState({selectedProfileFieldKey: value})}
                            onAddItem={(event, {value}) => this.setState({allProfileFieldKeys: this.state.allProfileFieldKeys.concat(value).sort(), selectedProfileFieldKey: value})}
                        />
                        <Form.Field
                            label="Field Value"
                            control={Input}
                            value={this.state.selectedProfileFieldValue}
                            onChange={(event, {value}) => this.setState({selectedProfileFieldValue: value})}
                        />
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Button disabled={this.props.userIsEditor} onClick={() => this.addFieldToProfile()} fluid>Add Field</Button>
                        </Form.Field>
                    </Form.Group>
                    <Form.Field>
                        <label htmlFor="properties">Ad Profile Properties</label>
                        <Grid.Row>
                            <div style={{ height: "25em" }} ref={elem => this.container = elem} />
                        </Grid.Row>
                    </Form.Field>
                </Form>
            </Container>
        );
    }
}
