import React, {useState, useEffect} from "react";
import {Button, Container, Grid, Icon, Input, Menu, Message, Modal, Table} from "semantic-ui-react";

export default function ProfileExportModal(props) {
    const [profileIds, setProfileIds] = useState([]);
    const [profileValues, setProfileValues] = useState({});
    const [selectedId, setSelectedId] = useState("");
    const [selectedProfile, setSelectedProfile] = useState({});
    const [exportFilename, setExportFilename] = useState("export.json");

    useEffect(function updateProfileIds() {
        setProfileIds(Object.keys(props.selectedProfiles));
        setProfileValues(props.selectedProfiles);
    }, [props.selectedProfiles]);

    useEffect(function updateSelectedProfile() {
        if (profileValues.hasOwnProperty(selectedId)) {
            setSelectedProfile(profileValues[selectedId]);
        } else {
            setSelectedProfile({});
        }
    }, [selectedId]);

    useEffect(function clearOnClose() {
        if (!props.open) {
            setSelectedProfile({});
            setSelectedId("");
            setExportFilename("export.json");
        }
    }, [props.open]);

    return (
        <Modal
            size="large"
            open={props.open}
            onClose={() => props.setOpen(false)}
            trigger={props.trigger}
            style={{position: "relative"}}
        >
            <Modal.Header>
                Ad Profile Export
            </Modal.Header>
            <Modal.Content scrolling style={{height: "50vh"}}>
                <Modal.Description>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Input
                                    label="Export Filename"
                                    value={exportFilename}
                                    onChange={(event, {value}) => setExportFilename(value)}
                                    fluid
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Column width={3}>
                            <Menu vertical fluid secondary>
                                {
                                    profileIds.map(id =>
                                        <Menu.Item
                                            key={`profile-export-${id}`}
                                            active={id === selectedId}
                                            onClick={() => setSelectedId(id)}
                                        >{id}</Menu.Item>
                                    )
                                }
                            </Menu>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            {
                                !selectedId ?
                                    <Message icon color="yellow">
                                        <Icon name="info"/>
                                        If you wish to inspect the profiles to export, you can click on the IDs on the left. You can also just click the export button.
                                    </Message> :
                                Object.keys(selectedProfile).length > 0 ?
                                    <Container>
                                        <Table fixed celled striped>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;Brand</Table.HeaderCell>
                                                    <Table.Cell>{selectedProfile.brand}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Platform</Table.HeaderCell>
                                                    <Table.Cell>{selectedProfile.platform}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Content Type</Table.HeaderCell>
                                                    <Table.Cell>{selectedProfile.contentType}</Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                        <Table fixed celled striped>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell colSpan="2">Ad Profile Properties</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {
                                                    Object.keys(selectedProfile.profile).map(key =>
                                                        <Table.Row>
                                                            <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{key}</Table.HeaderCell>
                                                            <Table.Cell>{selectedProfile.profile[key]}</Table.Cell>
                                                        </Table.Row>
                                                    )
                                                }
                                            </Table.Body>
                                        </Table>
                                    </Container> : ""
                            }
                        </Grid.Column>
                    </Grid>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.setOpen(false)} secondary>Cancel</Button>
                <Button onClick={() => props.exportJSON(exportFilename)} primary>Export</Button>
            </Modal.Actions>
        </Modal>
    );
};
